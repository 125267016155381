import React, { useState } from "react";
import SecondaryLabel from "../Form/Pieces/SecondaryLabel";
import Text from "../Form/Text";
import {
  handleUpdateQuestion,
  handleUpdateTopicNotes,
} from "../../../api/discovery-call";
import { getAnswer, getTopicNotes } from "../../../utilities/utilities";
import TextArea from "../Form/TextArea";
import {
  selectCurrentDiscovery,
  updateDiscoveryCall,
} from "../discoveryCallSlice";
import { useDispatch, useSelector } from "react-redux";

const SalesChannels = (props) => {
  const currentDisco = useSelector(selectCurrentDiscovery);
  const dispatch = useDispatch();
  const businessType = currentDisco.business_type.id;
  let [values, setValues] = useState({});
  let [showChannelCreator, setShowChannelCreator] = useState(false);

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const formatPercent = (percent) => {
    const formatted = String(percent).replace(/[^0-9\.]+/g, "");
    return formatted;
  };

  const handleUpdateChannel = (event) => {
    const { name, value } = event.target;

    const formattedVal = name === "percent" ? parseInt(value) : value;

    dispatch(
      updateDiscoveryCall(
        "discovery_channels",
        "updateDiscoveryChannel",
        { id: parseInt(event.target.dataset.id), [name]: formattedVal },
        currentDisco.id
      )
    );
  };

  const saveNewChannel = async () => {
    const newName = document.getElementById("new-name");
    const newPercent = document.getElementById("new-percent");
    const newNotes = document.getElementById("new-notes");

    await dispatch(
      updateDiscoveryCall(
        "discovery_channels",
        "createDiscoveryChannel",
        {
          discovery_id: currentDisco.id,
          name: newName.value,
          percent: parseInt(newPercent.value),
          notes: newNotes.value,
        },
        currentDisco.id
      )
    );

    newName.value = "";
    newPercent.value = "";
    newNotes.value = "";
    setShowChannelCreator(false);
  };

  return (
    <div className="box" style={{ padding: "2rem" }}>
      <div className="discovery-form sales-channels columns is-centered is-vcentered is-mobile is-multiline">
        <div className="column is full">
          <label className="label">
            {businessType === 1001 && (
              <span>
                What are the Key Lead Generation Channels for your Business by
                %? (i.e. Website, Sales Team, Partners, etc.)
              </span>
            )}
            {businessType === 1002 && (
              <span>
                What are the Key Sales Channels for your Business by %? (i.e.
                eCommerce, Marketplace Sales (Amazon, etc.), Offline Brick &
                Mortar Sales (Target, Walmart, etc.)
              </span>
            )}
            {businessType === 1003 && (
              <span>
                What are the Key Lead Generation Channels for your Business by
                %? (i.e. Website, Sales Team, Partners, Trade Shows, etc.)
              </span>
            )}
          </label>

          {currentDisco.channels.length > 0 &&
            currentDisco.channels.map((channel, i) => {
              return (
                <div
                  className="columns is-vcentered is-multiline fieldset-row"
                  key={`channel-${i}`}
                >
                  <div className="disco-form-field column is-6">
                    <SecondaryLabel text="Channel" />
                    <Text
                      name={"name"}
                      defaultValue={channel.name}
                      onChange={handleFieldChange}
                      onBlur={handleUpdateChannel}
                      dataId={channel.id}
                    />
                  </div>
                  <div className="disco-form-field column is-6">
                    <SecondaryLabel text="% of Sales" />
                    <Text
                      name={"percent"}
                      defaultValue={channel.percent}
                      onChange={(e) => {
                        const formatted = formatPercent(e.target.value);
                        e.target.value = formatted;
                        handleUpdateChannel(e);
                      }}
                      onBlur={handleUpdateChannel}
                      dataId={channel.id}
                    />
                  </div>
                  <div className="disco-form-field column is-full notes">
                    <SecondaryLabel text="Notes" />
                    <Text
                      name={"notes"}
                      defaultValue={channel.notes}
                      onChange={handleFieldChange}
                      onBlur={handleUpdateChannel}
                      dataId={channel.id}
                    />
                  </div>
                </div>
              );
            })}

          {showChannelCreator && (
            <div className="columns is-vcentered is-multiline fieldset-row">
              <div className="disco-form-field column is-6">
                <SecondaryLabel text="Channel" />
                <Text name={"name"} id={"new-name"} defaultValue={""} />
              </div>
              <div className="disco-form-field column is-6">
                <SecondaryLabel text="% of Sales" />
                <Text
                  name={"percent"}
                  id={"new-percent"}
                  defaultValue={"0"}
                  onChange={(e) => {
                    const formatted = formatPercent(e.target.value);
                    e.target.value = formatted;
                  }}
                />
              </div>
              <div className="disco-form-field column is-full notes">
                <SecondaryLabel text="Notes" />
                <Text name={"notes"} id={"new-notes"} defaultValue={""} />
              </div>
              <div className="disco-form-field column is-full add-fields-column">
                <button className="add-fields-button" onClick={saveNewChannel}>
                  Save
                </button>
              </div>
            </div>
          )}

          {!showChannelCreator && (
            <div
              className="column add-row"
              onClick={() => setShowChannelCreator(true)}
            >
              <span className="plus-btn"></span>
              <span>Add a new channel</span>
            </div>
          )}
        </div>

        {businessType !== 1002 && (
          <>
            <div className="disco-form-field column is-full">
              <label className="label">
                What is the sales process like for incoming leads?
              </label>
              <TextArea
                name="question-sales-process"
                defaultValue={getAnswer(currentDisco.questions, 1025)}
                onChange={handleFieldChange}
                onBlur={handleUpdateQuestion(
                  1025,
                  currentDisco.id,
                  dispatch,
                  (val) => {
                    return `""${val}""`;
                  }
                )}
                rows={4}
              />
            </div>
            <div className="disco-form-field column is-full">
              <label className="label">
                What is the average length of sales cycle?
              </label>
              <TextArea
                name="question-cycle-length"
                defaultValue={getAnswer(currentDisco.questions, 1027)}
                onChange={handleFieldChange}
                onBlur={handleUpdateQuestion(1027, currentDisco.id, dispatch)}
                rows={1}
                style={{ resize: "none" }}
              />
            </div>
            <div className="disco-form-field column is-full">
              <label className="label">
                How many SQL's do you generate per month on average?
              </label>
              <TextArea
                name="question-sqls"
                defaultValue={getAnswer(currentDisco.questions, 1029)}
                onChange={handleFieldChange}
                onBlur={handleUpdateQuestion(1029, currentDisco.id, dispatch)}
                rows={1}
                style={{ resize: "none" }}
              />
            </div>
            <div className="disco-form-field column is-full">
              <label className="label">
                What is average close ratio on a sales qualified lead?
              </label>
              <TextArea
                name="question-close-ratio"
                defaultValue={getAnswer(currentDisco.questions, 1031)}
                onChange={handleFieldChange}
                onBlur={handleUpdateQuestion(1031, currentDisco.id, dispatch)}
                rows={1}
                style={{ resize: "none" }}
              />
            </div>
          </>
        )}

        <div className="disco-form-field column is-full">
          <label className="label">Other notes</label>
          <TextArea
            name={"sales-channels-topic-notes"}
            defaultValue={getTopicNotes(currentDisco.topics, 1003)}
            onChange={handleFieldChange}
            onBlur={handleUpdateTopicNotes(currentDisco.id, 1003, dispatch)}
          />
        </div>
      </div>
    </div>
  );
};

export default SalesChannels;
