import React, {useEffect, useState} from 'react';
import Container from "../../components/Container";
import Tiles from "../../components/Discovery/Tiles";
import {getNovaUrlBase, isEmptyInput} from "../../utilities/utilities";
import PreCall from "../../components/Discovery/Views/PreCall";
import Marketing from "../../components/Discovery/Views/Marketing";
import SalesChannels from "../../components/Discovery/Views/SalesChannels";
import ProductsRevenue from "../../components/Discovery/Views/ProductsRevenue";
import KPIs from "../../components/Discovery/Views/KPIs";
import Budget from "../../components/Discovery/Views/Budget";
import BuyingCriteria from "../../components/Discovery/Views/BuyingCriteria";
import AuditFraming from "../../components/Discovery/Views/AuditFraming";
import {
    createNewDiscoveryCall,
    createClient,
    fetchDiscoveryCallById,
    selectCurrentClient,
    updateClient, selectCurrentDiscovery
} from "./discoveryCallSlice";
import {useDispatch, useSelector} from "react-redux";
import Text from "./Form/Text";
import Select from "./Form/Select";
import DiscoveryHeader from "./DiscoveryHeader";

const DiscoveryCallPage = () => {
    /* Topics are the different views within the discovery UI (i.e. PreCall, Products & Revenue, etc) */
    let [allTopics, setAllTopics] = useState([]);
    
    /* Current step user is on in the PreCall modal sequence */
    let [preCallStep, setPreCallStep] = useState(0);
    
    /* Controls display of the initial PreCall modal */
    let [showPreCallModal, setShowPreCallModal] = useState(true);

    /* The currently active topic that is being viewed (i.e. PreCall, Products & Revenue, etc) */
    let [currentView, setView] = useState(null);

    /* Tracking pre-call modal form values in local state */
    let [formValues, updateFormValues] = useState({});
    
    const currentClient = useSelector(selectCurrentClient);
    const currentDiscovery = useSelector(selectCurrentDiscovery);
    const dispatch = useDispatch();

    console.log('currentDiscovery', currentDiscovery);
    console.log('currentClient', currentClient);

    useEffect(() => {
        fetchTopics();
    }, []);

    useEffect(() => {
        if (window) {
            const urlParams = new URLSearchParams(window.location.search);
            const discoId = urlParams.get('id');
            if (discoId) {
                dispatch(fetchDiscoveryCallById(discoId));
                setPreCallStep(5);
            }
        }
    }, [dispatch]);

    /**
     * On initial render, check if we have a topic in the url query string
     */
    useEffect(() => {
        if (window) {
            const urlParams = new URLSearchParams(window.location.search);
            const topic = urlParams.get('topic');
            if (topic && topic !== 'null' && topic !== null) {
                setView(topic);
            }
        }
    }, []);

    /**
     * Update the url in address bar when switching topic views
     */
    useEffect(() => {
        if (window) {
            const {origin, pathname} = window.location;
            const urlParams = new URLSearchParams(window.location.search);
            const discoId = urlParams.get('id') ? urlParams.get('id') : currentDiscovery.id;

            const base = origin + pathname + '?id=' + discoId;
            const newUrl = base + '&topic=' + currentView;

            if (currentView && currentView !== 'null') {
                window.history.replaceState({}, '', newUrl);
            }
        }
    }, [currentView]);

    const fetchTopics = () => {
        console.log('fetching topics');
        const url = 'https://platform.powerdigital.dev/api/v1/';
        const query = `{ topics {
                        id
                        name
                        slug
                        order
                        questions {
                            id
                            question
                            type
                            fields
                            business_type {
                                id
                            }
                        }
                       } }`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 4igtrbQOML7CupBqqQyra3nNpkKtBglug9yVfQ8eV2G6EsieFnsIivNorEut'
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .then(res => setAllTopics(res.data.topics))
            .catch(err => console.log(err.message));
    };

    const goToNext = () => {
        if (preCallStep !== _preCallFields.length) {
            setPreCallStep(preCallStep + 1);
        }

        if (preCallStep === 1) {
            dispatch(createClient(formValues['client.name']));
        }

        if (preCallStep === 2) {
            dispatch(updateClient({
                id: currentClient.id,
                business_type_id: formValues['client.business_type.id'],
                website: formValues['client.website'],
            }))
        }

        if (preCallStep === 3) {
            dispatch(updateClient({
                id: currentClient.id,
                industry_id: parseInt(formValues['client.industry.id'])
            }));
        }

        if (preCallStep === 4) {
            dispatch(updateClient({
                id: currentClient.id, lead_source_id: parseInt(formValues['client.lead_source.id'])
            }));

            dispatch(createNewDiscoveryCall(currentClient.id, currentClient.business_type.id));
        }
    };

    const handleFieldChange = (event) => {
        const {name, value} = event.target;

        let newVal = name.endsWith('.id') ? parseInt(value) : value;

        updateFormValues({
            ...formValues,
            [name]: newVal
        });

    };

    const handleFieldBlur = (event) => {
        const {name, value} = event.target;

        let newVal = name.endsWith('.id') ? parseInt(value) : value;

        updateFormValues({
            ...formValues,
            [name]: newVal
        });

    };

    const handleChangeTopicView = (topic) => {
        setView(topic);
    };

    const setBusinessType = (typeId) => {
        updateFormValues({
            ...formValues,
            'client.business_type.id': typeId
        });
    };

    const getButtonClass = (typeId) => {
        const currentType = formValues['client.business_type.id'];
        if (typeId === currentType) {
            return 'active'
        } else {
            return 'inactive'
        }
    };

    const getQuestionsByTopic = (topic) => {
        const filtered = allTopics.filter(t => {
            return t.slug === topic;
        })[0];
        return filtered.questions;
    };

    const renderPreCallNav = () => {
        const buttonText = preCallStep === _preCallFields.length - 1 ? 'Start Call' : 'Next';

        const formKey = Object.keys(formValues)[preCallStep];
        let opts = {};
        if (isEmptyInput(formValues[formKey])) {
            opts['disabled'] = 'disabled';
        }

        return (
            <div className="pre-call-nav">
                <button className="next" aria-label="next" onClick={goToNext} {...opts}>
                    <span>{buttonText}</span>
                    <img src="../../img/chev-right.png" alt={buttonText} />
                </button>
            </div>
        )
    };

    const renderPreCallModal = () => {
        return (
            <>
                {showPreCallModal &&
                <div id="modal" className="modal pre-call-info-modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-content">
                        <div className="box">
                            {renderPreCallStep()}
                            {renderPreCallNav()}
                        </div>

                    </div>

                    <a href={`${getNovaUrlBase()}/audits/`} onClick={() => setShowPreCallModal(!showPreCallModal)}>
                        <button className="modal-close is-large" aria-label="close"></button>
                    </a>
                </div>
                }
            </>
        )
    };

    const _preCallFields = [
        {
            field:
                <div style={{margin: '20px 0', textAlign: 'center'}}>
                    <div
                        className={`business-type-button ${getButtonClass(1003)}`}
                        onClick={() => setBusinessType(1003)}>
                        <img src="../../img/type-b2b.png" alt="B2B" /><span>B2B</span>
                    </div>
                    <div
                        className={`business-type-button ${getButtonClass(1001)}`}
                        onClick={() => setBusinessType(1001)}>
                        <img src="../../img/type-leadgen.png" alt="B2C - LeadGen" /><span>B2C - LeadGen</span>
                    </div>
                    <div
                        className={`business-type-button ${getButtonClass(1002)}`}
                        onClick={() => setBusinessType(1002)}>
                        <img src="../../img/type-ecomm.png" alt="Ecomm" /><span>B2C - Ecomm</span>
                    </div>
                </div>
        },
        {
            field:
                <>
                    <label className="label">Company Name</label>
                    <Text
                        name="client.name"
                        value={formValues['client.name']}
                        onChange={handleFieldChange}
                        onBlur={handleFieldBlur}
                        addlClasses="is-full"
                    />
                </>
        },
        {
            field:
                <>
                    <label className="label">Website URL</label>
                    <Text
                        name="client.website"
                        value={formValues['client.website'] || ''}
                        onChange={handleFieldChange}
                        onBlur={handleFieldBlur}
                        placeholder="e.g. http://powerdigital.com"
                        addlClasses="is-full"
                    />
                </>
        },
        {
            field:
                <>
                    <label className="label">Industry</label>
                    <Select
                        name="client.industry.id"
                        options="industries"
                        value={formValues['client.industry.id'] || null}
                        onChange={handleFieldChange}
                        onBlur={handleFieldBlur}
                        rounded={true}
                        addlClasses="is-full"
                    />
                </>
        },
        {
            field:
                <>
                    <label className="label">Lead Source</label>
                    <Select
                        name="client.lead_source.id"
                        options="lead_sources"
                        value={formValues['client.lead_source.id'] || null}
                        onChange={handleFieldChange}
                        onBlur={handleFieldBlur}
                        rounded={true}
                        addlClasses="is-full"
                    />
                </>
        }
    ];

    const renderPreCallStep = () => {
        if (preCallStep <= _preCallFields.length - 1) {
            return _preCallFields[preCallStep].field;
        }
    };

    const renderTopics = () => {
        return (
            <>
                <div className="columns" style={{margin: 0, bottom: 60, position: 'relative', marginBottom: '-50px'}}>
                    <div className="column is-full top-links">
                        <DiscoveryHeader
                            clientName={currentDiscovery.client ? currentDiscovery.client.name : currentClient.name}
                        />
                    </div>
                </div>
                <div className="discovery-view-inner topics">
                    <h1>Topics</h1>
                    <div className="columns is-multiline">
                        {allTopics.map((topic, index) => {
                            return (
                                <div className="column" key={`${topic.slug}-${index}`}>
                                    <div className="box has-text-centered topic-box" key={topic.slug} onClick={() => handleChangeTopicView(topic.slug)}>
                                        <div className="text">
                                            <span className="title">{topic.name}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    };

    const pitchUrl = "https://docs.google.com/document/d/1l15iVHa9s73m_A4wULQcHqJ6nWZ6zBQ8xYKZ7-v_syk/edit";

    const renderTopicView = () => {
        return (
            <>
                <div className="columns" style={{margin: 0, bottom: 60, position: 'relative'}}>
                    <div className="column is-full top-links">
                        {/*<div className="breadcrumbs">*/}
                        {/*    <a className="home-icon" href={`${getNovaUrlBase()}/`}>*/}
                        {/*        <img src="../../img/nova-home_ICON.svg" alt="Nova" />*/}
                        {/*    </a>*/}
                        {/*    <span className="breadcrumb-divider"></span>*/}
                        {/*    <a href={`${getNovaUrlBase()}/audits`} style={{color: '#85a9ce', fontWeight: 600}}>All Audits</a>*/}
                        {/*    <span className="breadcrumb-divider"></span>*/}
                        {/*    <span>{currentDiscovery.client.name} Discovery</span>*/}
                        {/*    <h3 className="page-heading">{currentDiscovery.client.name} Discovery</h3>*/}
                        {/*</div>*/}
                        <DiscoveryHeader clientName={currentDiscovery.client.name}/>
                        <div className="action-links">

                            {currentDiscovery !== null &&
                            <>
                                <a href={`${getNovaUrlBase()}/audits/${currentDiscovery.audit.id}`}>View Audit</a>
                                <span className="action-divider">|</span>
                                <a href={`${getNovaUrlBase()}/audits/${currentDiscovery.audit.id}/survey`}>View Analyst Survey</a>
                                <span className="action-divider">|</span>
                            </>
                            }

                            <a className="elevator-pitch-button" href={pitchUrl} target="_blank" rel="noopener noreferrer">
                                <span>View Elevator Pitch</span>
                                <span className="pitch-icon">
                                <img src="../../img/nova-deck_ICON.svg" alt="Nova Pitch Deck" />
                            </span>
                            </a>
                        </div>

                    </div>
                </div>
                <Tiles
                    onViewChange={handleChangeTopicView}
                    activeItem={currentView}
                    items={allTopics}
                    wrapperStyle={{marginTop: -60}}
                    wrapperClass="topics"
                />
                {allTopics.filter(topic => {return topic.slug === currentView}).map(topic => {

                    if (topic.slug === 'pre-call') {
                        return <PreCall
                            key={'pre-call'}
                            questions={getQuestionsByTopic('pre-call')}
                        />
                    } else if (topic.slug === 'marketing-strategy') {
                        return <Marketing
                            key={'marketing'}
                        />
                    } else if (topic.slug === 'sales-channels') {
                        return <SalesChannels
                            key={'sales-channels'}
                        />
                    } else if (topic.slug === 'products-revenue') {
                        return <ProductsRevenue
                            key={'products-revenue'}
                        />
                    } else if (topic.slug === 'kpis') {
                        return <KPIs
                            key={'kpis'}
                        />
                    } else if (topic.slug === 'budget') {
                        return <Budget
                            key={'budget'}
                        />
                    } else if (topic.slug === 'buying-criteria') {
                        return <BuyingCriteria
                            key={'buying-criteria'}
                        />
                    } else if (topic.slug === 'audit-framing') {
                        return <AuditFraming
                            key={'audit-framing'}
                        />
                    }

                })}
            </>
        )
    };

    const renderErrorAlert = () => {
        return (
          <div className="disco-error-alert">
              <div data-show="true" className="ant-alert ant-alert-warning ant-alert-with-description">
                  <span role="img" aria-label="exclamation-circle" className="anticon anticon-exclamation-circle ant-alert-icon">
                      <svg
                  viewBox="64 64 896 896" focusable="false" className="" data-icon="exclamation-circle" width="1em"
                  height="1em" fill="currentColor" aria-hidden="true"><path
                  d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path
                  d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
                      </svg>
                  </span>
                  <span className="ant-alert-message">Discovery call not found</span>
                  <span className="ant-alert-description">
                      <p><a href={`${getNovaUrlBase()}/audits/`}>Back to list of Audits</a></p>
                  </span>
              </div>
          </div>
        )
    };

    const renderView = () => {
        const lastStep = _preCallFields.length;
        if (currentDiscovery !== null) {
            return (
                <div className="discovery-view">
                    {preCallStep < lastStep &&
                    renderPreCallModal()
                    }

                    {preCallStep >= lastStep && !currentView &&
                    renderTopics()
                    }

                    {currentView !== null &&
                    renderTopicView()
                    }
                </div>
            )
        } else {
            return (
                <div className="discovery-view">
                    {renderErrorAlert()}
                </div>
            )
        }

    };

    const saveExitLink = () => {
        let url;
        if (currentDiscovery !== null) {
            url = `${getNovaUrlBase()}/audits/${currentDiscovery.audit.id}`
        } else {
            url = `${getNovaUrlBase()}/audits/`
        }
        return <a href={url} className="btn btn-default btn-save">Save & Exit</a>
    };

    return (
        <Container mx='auto'>
            <div className="discovery-wrapper">
                {renderView()}
                {preCallStep > 4 && currentView && currentDiscovery !== null &&
                    saveExitLink()
                }
            </div>
        </Container>
    )

};

export default DiscoveryCallPage;