import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {formatDate, parseDate} from "react-day-picker/moment";

const DatePicker = (props) => {
    return (
        <div>
            <DayPickerInput
                name={props.name}
                value={props.value}
                onDayChange={props.onChange}
                format="MM/DD/YYYY"
                formatDate={formatDate}
                parseDate={parseDate}
                //placeholder={`${formatDate(new Date())}`}
                placeholder="Select a Date"
            />
        </div>
    )
};

export default DatePicker;