import React, {useState} from 'react';
import SecondaryLabel from "../Form/Pieces/SecondaryLabel";
import Text from "../Form/Text";
import TextArea from "../Form/TextArea";
import {selectCurrentDiscovery, updateDiscoveryCall} from "../discoveryCallSlice";
import {useDispatch, useSelector} from "react-redux";
import {getTopicNotes} from "../../../utilities/utilities";
import {handleUpdateTopicNotes} from "../../../api/discovery-call";

const KPIs = () => {
    const currentDisco = useSelector(selectCurrentDiscovery);
    const dispatch = useDispatch();
    const businessType = currentDisco.business_type.id;
    let [values, setValues] = useState({});
    let [showGoalCreator, setShowGoalCreator] = useState(false);
    let [showGrowthCreator, setShowGrowthCreator] = useState(false);

    const handleFieldChange = (event) => {
        const {name, value} = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleUpdateGoal = (event) => {
        const {name, value} = event.target;

        dispatch(updateDiscoveryCall(
            'discovery_goals',
            'updateDiscoveryGoal',
            {id: parseInt(event.target.dataset.id), [name]: value},
            currentDisco.id
        ));
    };

    const saveNewGoal = async () => {
        const newType = document.getElementById('new-type');
        const newMetric = document.getElementById('new-metric');

        await dispatch(updateDiscoveryCall(
            'discovery_goals',
            'createDiscoveryGoal',
            {
                discovery_id: currentDisco.id,
                type: newType.value,
                metric: newMetric.value
            },
            currentDisco.id
        ));

        newType.value = '';
        newMetric.value = '';
        setShowGoalCreator(false);
    };

    const handleUpdateGrowth = (event) => {
        const {name, value} = event.target;

        const formattedVal = `""${value}""`;

        dispatch(updateDiscoveryCall(
            'discovery_growths',
            'updateDiscoveryGrowth',
            {id: parseInt(event.target.dataset.id), [name]: formattedVal},
            currentDisco.id
        ));
    };

    const saveNewGrowth = async () => {
        const newGrowth = document.getElementById('new-growth');
        const textVal = `""${newGrowth.value}""`;

        await dispatch(updateDiscoveryCall(
            'discovery_growths',
            'createDiscoveryGrowth',
            {
                discovery_id: currentDisco.id,
                goal: textVal
            },
            currentDisco.id
        ));

        newGrowth.value = '';
        setShowGrowthCreator(false);
    };

    return (
        <div className="box" style={{padding: '2rem'}}>
            <div className="discovery-form kpis columns is-centered is-vcentered is-mobile is-multiline">

                <div className="column is full">

                    {businessType === 1002 &&
                        <label className="label">
                            What are your marketing goals with bringing on a new partner? (i.e. ROAS, eCommerce Volume Growth, Organic Traffic/Ranking Growth, etc.)
                        </label>
                    }

                    {(businessType === 1001 || businessType === 1003) &&
                        <label className="label">
                            "Which of the following KPIs are you focused on and what is your Target Number?<br />
                            (CPL, MQL to SQL Ratio, CAC (Customer Acqusition Cost) to LTV (Life Time Value) Ratio, Not Clear on Goals and Need Help Establishing)"
                        </label>
                    }

                    {currentDisco.goals.length > 0 && currentDisco.goals.map((goal, i) => {
                        return (
                            <div className="columns is-centered is-vcentered is-multiline fieldset-row" key={`goal-${i}`}>
                                <div className="disco-form-field column is-half">
                                    <SecondaryLabel text="Goal Type" />
                                    <Text
                                        name={'type'}
                                        defaultValue={goal.type}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateGoal}
                                        dataId={goal.id}
                                    />
                                </div>
                                <div className="disco-form-field column is-half">
                                    <SecondaryLabel text="Goal Metric" />
                                    <Text
                                        name={'metric'}
                                        defaultValue={goal.metric}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateGoal}
                                        dataId={goal.id}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    {showGoalCreator &&
                        <div className="columns is-centered is-vcentered is-multiline fieldset-row">
                            <div className="disco-form-field column">
                                <SecondaryLabel text="Goal Type" />
                                <Text
                                    name={'type'}
                                    id={'new-type'}
                                    defaultValue={''}
                                />
                            </div>
                            <div className="disco-form-field column">
                                <SecondaryLabel text="Goal Metric" />
                                <Text
                                    name={'metric'}
                                    id={'new-metric'}
                                    defaultValue={''}
                                />
                            </div>
                            <div className="disco-form-field column is-full add-fields-column">
                                <button className="add-fields-button" onClick={saveNewGoal}>Save</button>
                            </div>
                        </div>
                    }

                    {!showGoalCreator &&
                    <div className="column add-row" onClick={() => setShowGoalCreator(true)}>
                        <span className="plus-btn"></span><span>Add a new goal</span>
                    </div>
                    }

                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">What are your business growth goals?</label>

                    {currentDisco.growths.length > 0 && currentDisco.growths.map((growth, i) => {
                        return (
                            <div className="columns is-centered is-vcentered is-multiline fieldset-row" key={`growth-${i}`}>
                                <div className="disco-form-field column is-full">
                                    <SecondaryLabel text="Growth Goal" />
                                    <TextArea
                                        name={'goal'}
                                        defaultValue={growth.goal}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateGrowth}
                                        dataId={growth.id}
                                        rows={2}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    {showGrowthCreator &&
                    <div className="columns is-centered is-vcentered is-multiline fieldset-row">
                        <div className="disco-form-field column">
                            <SecondaryLabel text="Growth Goal" />
                            <TextArea
                                name={'goal'}
                                id={'new-growth'}
                                defaultValue={''}
                                rows={2}
                            />
                        </div>
                        <div className="disco-form-field column is-full add-fields-column">
                            <button className="add-fields-button" onClick={saveNewGrowth}>Save</button>
                        </div>
                    </div>
                    }

                    {!showGrowthCreator &&
                    <div className="column add-row" onClick={() => setShowGrowthCreator(true)}>
                        <span className="plus-btn"></span><span>Add a new business growth goal</span>
                    </div>
                    }
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">Other notes</label>
                    <TextArea
                        name={'kpis-topic-notes'}
                        defaultValue={getTopicNotes(currentDisco.topics,1005)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateTopicNotes(currentDisco.id, 1005, dispatch)}
                    />
                </div>

            </div>
        </div>
    )

};

export default KPIs;