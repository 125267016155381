import React, {useEffect, useState} from 'react';
import SecondaryLabel from "../Form/Pieces/SecondaryLabel";
import Text from "../Form/Text";
import {getAnswer, getFetchParams, getTopicNotes, isBlankRegex} from "../../../utilities/utilities";
import {handleUpdateQuestion, handleUpdateTopicNotes} from "../../../api/discovery-call";
import Tiles from "../Tiles";
import TextArea from "../Form/TextArea";
import Radio from "../Form/Radio";
import {
    fetchDiscoveryCallById,
    removeDiscoveryService,
    selectCurrentDiscovery,
    updateDiscoveryCall
} from "../discoveryCallSlice";
import {useDispatch, useSelector} from "react-redux";

const Marketing = () => {
    const currentDisco = useSelector(selectCurrentDiscovery);
    const dispatch = useDispatch();

    const businessType = currentDisco.business_type.id;
    let [allDepts, setAllDepts] = useState([]);
    let [currentDept, setDept] = useState(null);
    let [values, setValues] = useState({});
    let [showCompetitorCreator, setShowCompetitorCreator] = useState(false);
    let [showCustomerCreator, setShowCustomerCreator] = useState(false);
    let [deptNotes, setDeptNotes] = useState('');

    useEffect(() => {
        fetchDepartments();
    }, []);

    useEffect(() => {
        getDeptNotes();
    }, [currentDept]);

    const fetchDepartments = () => {
        console.log('fetching departments');
        const url = getFetchParams().url;
        const query = `{
                      departments {
                        id
                        name
                        slug
                        services {
                          id
                          name
                          slug
                          is_active
                          is_current
                          producer
                          grade
                        }
                      }
                    }`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .then(res => setAllDepts(res.data.departments))
            .catch(err => console.log(err.message));
    };

    const getServicesByDept = () => {
        if (typeof currentDept !== 'undefined' && typeof currentDept !== null) {
            const filtered =  allDepts.filter(dept => {
                return dept.slug === currentDept
            });
            if (filtered.length > 0) {
                return filtered[0].services;
            }
        }
    };

    const getActiveServicesByDept = () => {
        return currentDisco.services.map(service => {
            if (service.department.slug === currentDept && service.is_active === "yes") {
                return service
            }
        });
    };

    const getServicesToRender = () => {
        const getServices = getServicesByDept();
        if (getServices) {
            return getServices.map(item1 => {
                return Object.assign(item1, getActiveServicesByDept().find(item2 => item2 && item1.id === item2.id))
            })
        }
    };

    const getProgressIcon = (deptSlug, isActiveTile) => {
        let status = '';
        let icon;

        // get the department and its relevant services
        const deptServices = allDepts.filter(dept => {
            return dept.slug === deptSlug
        })[0].services;

        // get all is_active discoServices filtered by the department
        const activeServices = currentDisco.services.filter(svc => {
            return svc.department.slug === deptSlug
        });

        // update the department's services array with values from dept's active services
        // also need to filter down to only services with is_active === 'yes'
        const updatedVals = deptServices.map(item1 => {
            return Object.assign(item1, activeServices.find(item2 => item2 && item1.id === item2.id))
        }).filter(svc => {
            return svc.is_active === "yes"
        });

        // check if any services are "in progress"
        for (let item of updatedVals) {
            if (item.is_current === null || item.producer === null || item.grade === null ) {
                status = 'in-progress';
                break;
            } else {
                status = 'complete';
            }
        }

        // get the icon depending on dept status
        switch(status) {
            case 'in-progress':
                if (isActiveTile) {
                    icon = <img className="dept-status-icon" src="../../../img/icons/incomplete-inactive.svg" />;
                } else {
                    icon = <img className="dept-status-icon" src="../../../img/icons/incomplete-inactive.svg" />;
                }
                break;
            case 'complete':
                if (isActiveTile) {
                    icon = <img className="dept-status-icon" src="../../../img/icons/complete.svg" />;
                } else {
                    icon = <img className="dept-status-icon" src="../../../img/icons/complete.svg" />;
                }
        }

        return icon;
    };

    const getDeptNotes = () => {
        if (currentDisco.departments.length && currentDept !== null) {
            const filtered = currentDisco.departments.filter(d => {
                return d.slug === currentDept;
            });

            if (filtered.length) {
                if (filtered[0].notes !== null && !(isBlankRegex(filtered[0].notes))) {
                    setDeptNotes(filtered[0].notes);
                } else {
                    setDeptNotes('');
                }
            } else {
                setDeptNotes('');
            }
        }
    };

    const getDeptId = (dept) => {
        const filtered = allDepts.filter(d => {
            return d.slug === dept;
        });

        if (filtered.length) {
            return filtered[0].id
        }
    };

    const handleFieldChange = (event) => {
        const {name, value} = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleUpdateNotes = async (event) => {
        const {name, value} = event.target;

        setValues({
            ...values,
            [name]: value
        });

        if (name === 'dept-notes') {
            if (!isBlankRegex(value)) {
                await dispatch(updateDiscoveryCall(
                    'discovery_departments',
                    'updateDiscoveryDepartment',
                    {
                        discovery_id: currentDisco.id,
                        department_id: parseInt(getDeptId(currentDept)),
                        notes: `""${value}""`
                    },
                    currentDisco.id
                ));
            }
        }
    };

    const handleUpdateService = async (event) => {
        const {value} = event.target;
        const serviceId = parseInt(event.target.dataset.serviceId);
        const serviceName = event.target.dataset.serviceName;

        await dispatch(updateDiscoveryCall(
            'discovery_services',
            'updateDiscoveryService',
            {
                discovery_id: currentDisco.id,
                service_id: serviceId,
                is_active: "yes",
                [serviceName]: value
            },
            currentDisco.id
        ));
    };

    const handleUpdateCompetitor = (event) => {
        const {name, value} = event.target;

        dispatch(updateDiscoveryCall(
            'discovery_competitors',
            'updateDiscoveryCompetitor',
            {id: parseInt(event.target.dataset.id), [name]: value},
            currentDisco.id
        ));
    };

    const saveNewCompetitor = async () => {
        const newName = document.getElementById('new-name');
        const newWebsite = document.getElementById('new-website');

        await dispatch(updateDiscoveryCall(
            'discovery_competitors',
            'createDiscoveryCompetitor',
            {
                discovery_id: currentDisco.id,
                name: newName.value,
                website: newWebsite.value
            },
            currentDisco.id
        ));

        newName.value = '';
        newWebsite.value = '';
        setShowCompetitorCreator(false);
    };

    const handleUpdateCustomer = (event) => {
        const {name, value} = event.target;

        dispatch(updateDiscoveryCall(
            'discovery_customers',
            'updateDiscoveryCustomer',
            {id: parseInt(event.target.dataset.id), [name]: value},
            currentDisco.id
        ));
    };

    const removeService = async (service) => {
        await dispatch(updateDiscoveryCall(
            'discovery_services',
            'updateDiscoveryService',
            {
                discovery_id: currentDisco.id,
                service_id: service.id,
                is_active: "no",
                is_current: null,
                producer: null,
                grade: null,
                is_priority: null
            },
            currentDisco.id
        ));

        await dispatch(removeDiscoveryService(currentDisco.id, service.id));
        await dispatch(fetchDiscoveryCallById(currentDisco.id));
    }

    const renderRemoveServiceButton = (service) => {
        if (service.is_current || service.producer || service.grade) {
            return (
                <span className="remove-service-button" onClick={() => removeService(service)}>
                    remove service
                </span>
            )
        }
    }

    const saveNewCustomer = async () => {
        const newName = document.getElementById('new-customer-name');
        const newIndustry = document.getElementById('new-customer-industry');

        if (businessType === 1003) {
            await dispatch(updateDiscoveryCall(
                'discovery_customers',
                'createDiscoveryCustomer',
                {
                    discovery_id: currentDisco.id,
                    name: newName.value,
                    industry: newIndustry.value
                },
                currentDisco.id
            ));

            newName.value = '';
            newIndustry.value = '';
        }

        if (businessType === 1001 || businessType === 1002) {
            await dispatch(updateDiscoveryCall(
                'discovery_customers',
                'createDiscoveryCustomer',
                {
                    discovery_id: currentDisco.id,
                    name: newName.value,
                },
                currentDisco.id
            ));

            newName.value = '';
        }

        setShowCustomerCreator(false);
    };

    return (
        <div className="box" style={{padding: '2rem'}}>
            <div className="discovery-form marketing columns is-centered is-vcentered is-mobile is-multiline">

                <div className="disco-form-field column is-full" style={{padding: 20}}>
                    <div className="columns is-multiline">
                        <Tiles
                            onViewChange={(dept) => setDept(dept)}
                            activeItem={currentDept}
                            items={allDepts}
                            wrapperStyle={{marginBottom: 15}}
                            wrapperClass={'departments'}
                            addlTileClasses={['marketing']}
                            itemStyle={{
                                padding: '10px',
                                fontSize: '13px',
                                borderRadius: '20px'
                            }}
                            columnClasses={['is-2']}
                            heading="What marketing initiatives are you currently running?"
                            servicesByDept={getServicesByDept()}
                            getIcon={getProgressIcon}
                        />
                    </div>
                </div>

                <div className="disco-form-field column is-full marketing-services" style={{padding: 20, textAlign: 'center'}}>
                    {typeof getServicesToRender() !== 'undefined' && getServicesToRender().length > 0 &&
                        getServicesToRender().map((service, i) => {
                        const discoId = currentDisco.id;
                        return (
                            <div className="columns is-centered is-vcentered is-multiline fieldset-row" key={`channel-${i}`}>
                                <div className="disco-form-field column is-4" style={{textAlign: 'left'}}>
                                    <SecondaryLabel text="Service" style={{visibility: 'hidden'}}/>
                                    <Text
                                        name={'name'}
                                        value={service.name}
                                        dataId={service.id}
                                        readOnly={true}
                                        inputStyle={{border: 'none', fontWeight: 'bold', padding: '0px 11px 0 0', height: 'auto'}}
                                        addlClasses={['service-type']}
                                        wrapperStyle={{marginBottom: 0}}
                                    />
                                    {renderRemoveServiceButton(service)}
                                </div>
                                <div className="disco-form-field column is-2">
                                    <SecondaryLabel text="Current?" />
                                    <Radio
                                        name={'is_current'}
                                        key={`is_current-${service.id}`}
                                        dataId={service.id}
                                        dataName={'is_current'}
                                        value={service.is_current}
                                        onChange={handleUpdateService}
                                        choices={[
                                            {
                                                name: `${discoId}-${service.id}-is_current`,
                                                label: "Yes",
                                                value: "yes"
                                            },
                                            {
                                                name: `${discoId}-${service.id}-is_current`,
                                                label: "No",
                                                value: "no"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="disco-form-field column is-3">
                                    <SecondaryLabel text="Producer" />
                                    <Radio
                                        name={'producer'}
                                        key={`producer-${service.id}`}
                                        dataId={service.id}
                                        dataName={'producer'}
                                        value={service.producer}
                                        onChange={handleUpdateService}
                                        choices={[
                                            {
                                                name: `${discoId}-${service.id}-producer`,
                                                label: "In-house",
                                                value: "in-house"

                                            },
                                            {
                                                name: `${discoId}-${service.id}-producer`,
                                                label: "Partner",
                                                value: "partner"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="disco-form-field column is-3">
                                    <SecondaryLabel text="Grade" />
                                    <Radio
                                        name={'grade'}
                                        key={`grade-${service.id}`}
                                        dataId={service.id}
                                        dataName={'grade'}
                                        value={service.grade}
                                        onChange={handleUpdateService}
                                        choices={[
                                            {
                                                name: `${discoId}-${service.id}-grade`,
                                                label: "Weakness",
                                                value: "weakness"
                                            },
                                            {
                                                name: `${discoId}-${service.id}-grade`,
                                                label: "Opportunity",
                                                value: "opportunity"
                                            },
                                            {
                                                name: `${discoId}-${service.id}-grade`,
                                                label: "Strength",
                                                value: "strength"
                                            }
                                        ]}
                                    />
                                </div>

                            </div>
                        )
                    })
                    }
                </div>

                <div className="disco-form-field column is-full" style={{padding: '0 20px 12px'}}>
                    {currentDept !== null &&
                    <div className="disco-form-field department-notes" style={{textAlign: 'left', marginBottom: 50}}>
                        <SecondaryLabel text="Department Notes" />
                        <TextArea
                            name="dept-notes"
                            value={deptNotes}
                            onChange={(e) => setDeptNotes(e.target.value)}
                            onBlur={handleUpdateNotes}
                            rows={4}
                        />
                    </div>
                    }
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">What CRM are you using?</label>
                    <TextArea
                        name="question-crm"
                        defaultValue={getAnswer(currentDisco.questions, 1005)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1005, currentDisco.id, dispatch,(val) => {return val;})}
                        rows={1}
                        style={{resize: 'none'}}
                    />
                </div>

                {businessType !== 1002 &&
                    <>
                        <div className="disco-form-field column is-full">
                            <label className="label">Are you running email? If so, what platform?</label>
                            <TextArea
                                name="question-email-platform"
                                defaultValue={getAnswer(currentDisco.questions, 1023)}
                                onChange={handleFieldChange}
                                onBlur={handleUpdateQuestion(1023, currentDisco.id, dispatch,(val) => {return val;})}
                                rows={1}
                                style={{resize: 'none'}}
                            />
                        </div>
                        <div className="disco-form-field column is-full">
                            <label className="label">How big is the email list?</label>
                            <TextArea
                                name="question-email-list-size"
                                defaultValue={getAnswer(currentDisco.questions, 1041)}
                                onChange={handleFieldChange}
                                onBlur={handleUpdateQuestion(1041, currentDisco.id, dispatch,(val) => {return val;})}
                                rows={1}
                                style={{resize: 'none'}}
                            />
                        </div>
                    </>
                }

                <div className="disco-form-field column is-full" style={{marginTop: 30}}>
                    <label className="label">Can you describe your target customer?</label>
                    {currentDisco.customers.length > 0 && currentDisco.customers.map((customer, i) => {
                        return (
                            <div className="columns is-centered is-vcentered is-multiline fieldset-row" key={`customer-${i}`}>
                                {(businessType === 1001 || businessType === 1002) &&
                                    <div className="disco-form-field column is-full">
                                        <SecondaryLabel text="Customer" />
                                        <Text
                                            name={'name'}
                                            defaultValue={customer.name}
                                            onChange={handleFieldChange}
                                            onBlur={handleUpdateCustomer}
                                            dataId={customer.id}
                                        />
                                    </div>
                                }
                                {businessType === 1003 &&
                                    <>
                                        <div className="disco-form-field column is-half">
                                            <SecondaryLabel text="Title" />
                                            <Text
                                            name={'name'}
                                            defaultValue={customer.name}
                                            onChange={handleFieldChange}
                                            onBlur={handleUpdateCustomer}
                                            dataId={customer.id}
                                            />
                                        </div>
                                        <div className="disco-form-field column is-half">
                                            <SecondaryLabel text="Industry" />
                                            <Text
                                                name={'industry'}
                                                defaultValue={customer.industry}
                                                onChange={handleFieldChange}
                                                onBlur={handleUpdateCustomer}
                                                dataId={customer.id}
                                            />
                                        </div>
                                    </>
                                }

                            </div>
                        )
                    })}

                    {showCustomerCreator &&
                    <div className="columns is-centered is-vcentered is-multiline fieldset-row">

                        {(businessType === 1001 || businessType === 1002) &&
                            <div className="disco-form-field column">
                                <SecondaryLabel text="Customer" />
                                <Text
                                    name={'name'}
                                    id={'new-customer-name'}
                                    defaultValue={''}
                                />
                            </div>
                        }

                        {businessType === 1003 &&
                            <>
                                <div className="disco-form-field column">
                                    <SecondaryLabel text="Title" />
                                    <Text
                                        name={'name'}
                                        id={'new-customer-name'}
                                        defaultValue={''}
                                    />
                                </div>
                                <div className="disco-form-field column">
                                    <SecondaryLabel text="Industry" />
                                    <Text
                                    name={'industry'}
                                    id={'new-customer-industry'}
                                    defaultValue={''}
                                    />
                                </div>
                            </>
                        }

                        <div className="disco-form-field column is-full add-fields-column">
                            <button className="add-fields-button" onClick={saveNewCustomer}>Save</button>
                        </div>
                    </div>
                    }

                    {!showCustomerCreator &&
                    <div className="column add-row" onClick={() => setShowCustomerCreator(true)}>
                        <span className="plus-btn"></span><span>Add a new customer</span>
                    </div>
                    }
                </div>

                <div className="column is full">

                    <label className="label">Who are your top 3 competitors?</label>
                    {currentDisco.competitors.length > 0 && currentDisco.competitors.map((competitor, i) => {
                        return (
                            <div className="columns is-centered is-vcentered is-multiline fieldset-row" key={`competitor-${i}`}>
                                <div className="disco-form-field column is-half">
                                    <SecondaryLabel text="Company Name" />
                                    <Text
                                        name={'name'}
                                        defaultValue={competitor.name}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateCompetitor}
                                        dataId={competitor.id}
                                    />
                                </div>
                                <div className="disco-form-field column is-half">
                                    <SecondaryLabel text="URL" />
                                    <Text
                                        name={'website'}
                                        defaultValue={competitor.website}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateCompetitor}
                                        dataId={competitor.id}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    {showCompetitorCreator &&
                        <div className="columns is-centered is-vcentered is-multiline fieldset-row">
                            <div className="disco-form-field column">
                                <SecondaryLabel text="Company Name" />
                                <Text
                                    name={'name'}
                                    id={'new-name'}
                                    defaultValue={''}
                                />
                            </div>
                            <div className="disco-form-field column">
                                <SecondaryLabel text="URL" />
                                <Text
                                    name={'website'}
                                    id={'new-website'}
                                    defaultValue={''}
                                />
                            </div>
                            <div className="disco-form-field column is-full add-fields-column">
                                <button className="add-fields-button" onClick={saveNewCompetitor}>Save</button>
                            </div>
                        </div>
                    }

                    {!showCompetitorCreator &&
                    <div className="column add-row" onClick={() => setShowCompetitorCreator(true)}>
                        <span className="plus-btn"></span><span>Add a new competitor</span>
                    </div>
                    }

                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">Other notes</label>
                    <TextArea
                        name={'marketing-topic-notes'}
                        defaultValue={getTopicNotes(currentDisco.topics,1002)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateTopicNotes(currentDisco.id, 1002, dispatch)}
                    />
                </div>

            </div>
        </div>
    )

};

export default Marketing;