import React, {useEffect, useState} from 'react';
import {getFetchParams} from "../../../utilities/utilities";

const Select = (props) => {

    let [selectOptions, setSelectOptions] = useState([]);

    useEffect(() => {
        if (props.options) {
            fetchSelectOptions(props.options)
        }
    }, [props.options]);

    const fetchSelectOptions = (options) => {
        if (!options) return;
        const url = getFetchParams().url;

        const query = options === 'business_types' ?
            `
            { ${options} {
                id
                name
                slug
            } }
        ` :
            `
            { ${options} {
                id
                name
            } }
        `;

        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .then(res => setSelectOptions(res.data[`${options}`]))
            .catch(err => console.log(err.message));
    };

    let style = props.style;
    if (props.fullWidth) {
        style = {...style, width: '100%'}
    }

    return (

        <div className="field">

            <div className="control">

                <div className={`select ${(props.field || {}).rounded || props.rounded ? 'is-rounded' : ''}`} style={style}>

                    <select
                        name={props.name}
                        value={props.value || ''}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        style={style}
                    >

                        <option value="" disabled selected>Choose one...</option>

                        {selectOptions && selectOptions.map((option, index) => {
                            let handle = props.name ? props.name.replace(/\W+/g, '-').toLowerCase() : '';
                            const currentVal = parseInt(option.id);

                            return (
                                <option
                                    name={handle}
                                    value={currentVal || null}
                                    key={`option-key-${handle}-${index}`}
                                >
                                    {option.name}
                                </option>
                            )

                            // if (typeof props.decisionMaker !== 'undefined' && props.decisionMaker.id === currentVal) {
                            //     return (
                            //         <option
                            //             name={handle}
                            //             value={currentVal || null}
                            //             key={`option-key-${handle}-${index}`}
                            //             selected
                            //         >
                            //             {option.name}
                            //         </option>
                            //     )
                            // } else {
                            //     return (
                            //         <option
                            //             name={handle}
                            //             value={currentVal || null}
                            //             key={`option-key-${handle}-${index}`}
                            //         >
                            //             {option.name}
                            //         </option>
                            //     )
                            // }

                        })}

                    </select>

                </div>

            </div>

        </div>

    )

};

export default Select

Select.defaultProps = {
    placeholder: 'Choose one...',
    fullWidth: false
};