import React from 'react';
import {actions, useStateContext} from "../../state";


const TilesSubMenu = (props) => {

    const [state, dispatch] = useStateContext();

    const toggleServiceAndDataPoints = service => {
        const { handle } = service;
        const hasHandle = state.active.services.includes(handle);
        const type = hasHandle ? actions.disableService : actions.enableService;

        dispatch({ type, service });
    };

    const getMenuItemClass = (item) => {
        const itemName = item.replace(/\W+/g, '-').toLowerCase();

        if (props.activeSubMenuItems.includes(itemName)) {
            return 'active'
        } else {
            return '';
        }

    };

    let subMenuClasses = ['tile-sub-menu'];
    if (props.subMenuClasses) {
        subMenuClasses = [...subMenuClasses, props.subMenuClasses]
    }

    return (
        <div className={subMenuClasses.join(' ')}>
            <ul>
                {props.item.services.map(service => {
                    return (
                        <li
                            key={service.name}
                            className={getMenuItemClass(service.name)}
                            onClick={() => {
                                props.onSubMenuSelect(service);
                                toggleServiceAndDataPoints({ handle: service.handle })
                            }}
                        >
                            {service.name}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
};

export default TilesSubMenu;