import React from "react";
import PropTypes from 'prop-types';

const SecondaryLabel = (props) => {
    const defaultStyles = {
        fontFamily: "'Sohne Light', sans-serif",
        fontSize: '11px',
        textTransform: 'capitalize',
        color: '#262626',
        fontWeight: 400,
        marginBottom: '5px',
        height: '14px',
        display: 'block'
    };

    return (
        <span className="secondary-label" style={{...defaultStyles, ...props.style}}>{props.text}</span>
    )
};

export default SecondaryLabel;

SecondaryLabel.propTypes = {
    text: PropTypes.string,
    style: PropTypes.object
};

SecondaryLabel.defaultProps = {
    text: ''
};