import React, {useState} from 'react';

const TextArea = (props) => {

    let [showField, setShowField] = useState(false);

    let controlClasses = ['control'];
    let inputStyle = {};

    if (props.isCurrency) {
        controlClasses.push('has-icons-left');
        inputStyle = {...inputStyle, paddingLeft: 26}
    }

    if (props.style) {
        inputStyle = {...inputStyle, ...props.style}
    }

    const renderShowHideLink = () => {
        if (props.collapsible) {
            const text = showField ? 'Hide details' : 'Show details';

            return (
                <div className="collapsible-details-link" onClick={() => setShowField(!showField)}>
                    <img src="../../../img/info-circle.svg" /><span>{text}</span>
                </div>
            )
        }
    };

    let fieldClasses = ['field'];
    if (props.collapsible) {
        if (showField) {
            fieldClasses.push('show-field');
        } else {
            fieldClasses.push('hide-field');
        }
    }

    return (
        <>
            {renderShowHideLink()}
            <div className={fieldClasses.join(' ')}>
                <div className={controlClasses.join(' ')}>
                    <textarea
                        name={(props.field || {}).name || props.name || ''}
                        id={props.id}
                        value={props.value}
                        defaultValue={props.defaultValue}
                        className="textarea"
                        placeholder={(props.field || {}).placeholder || ''}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        rows={props.rows}
                        style={inputStyle}
                        data-id={props.dataId || null}
                    >
                    </textarea>
                    {props.isCurrency &&
                    <span className="icon is-small is-right dollars">
                      $
                    </span>
                    }
                </div>
            </div>
        </>
    )

};

export default TextArea;