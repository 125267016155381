import React from 'react';

const Radio = (props) => {
    const choices = props.field ? props.field.radioChoices : props.choices;
    return (
        <div className="field">
            <div className="control" style={{textAlign: 'center'}}>
                {/*{choices.map((choice, index) => {*/}
                {/*    if (props.value === choice.value) {*/}
                {/*        return (*/}
                {/*            <label className="radio" key={`${choice.name}-${index}`} style={{marginRight: 10, fontSize: 12}}>*/}
                {/*                <input*/}
                {/*                    type="radio"*/}
                {/*                    //value={choice.value || ''}*/}
                {/*                    value={choice.value}*/}
                {/*                    name={choice.name}*/}
                {/*                    style={{marginRight: 5}}*/}
                {/*                    onChange={props.onChange}*/}
                {/*                    checked={props.value === choice.value}*/}
                {/*                    data-service-id={props.dataId}*/}
                {/*                    data-service-name={props.dataName}*/}
                {/*                />*/}
                {/*                {choice.label}*/}
                {/*            </label>*/}
                {/*        )*/}
                {/*    }  else {*/}
                {/*        return (*/}
                {/*            <label className="radio" key={`${choice.name}-${index}`} style={{marginRight: 10, fontSize: 12}}>*/}
                {/*                <input*/}
                {/*                    type="radio"*/}
                {/*                    //value={choice.value || ''}*/}
                {/*                    value={choice.value}*/}
                {/*                    name={choice.name}*/}
                {/*                    style={{marginRight: 5}}*/}
                {/*                    onChange={props.onChange}*/}
                {/*                    checked={props.value === choice.value}*/}
                {/*                    data-service-id={props.dataId}*/}
                {/*                    data-service-name={props.dataName}*/}
                {/*                />*/}
                {/*                {choice.label}*/}
                {/*            </label>*/}
                {/*        )*/}
                {/*    }*/}
                {/*})}*/}
                {choices.map((choice, index) => {
                    return (
                        <label className="radio" key={`${choice.name}-${index}`} style={{marginRight: 10, fontSize: 12}}>
                            <input
                                type="radio"
                                value={choice.value}
                                name={choice.name}
                                style={{marginRight: 5}}
                                onChange={props.onChange}
                                checked={props.value === choice.value}
                                data-service-id={props.dataId}
                                data-service-name={props.dataName}
                            />
                            {choice.label}
                        </label>
                    )
                })}
            </div>
        </div>
    )
};

export default Radio;