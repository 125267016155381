import React, {useState} from 'react';
import SecondaryLabel from "../Form/Pieces/SecondaryLabel";
import Text from "../Form/Text";
import {selectCurrentDiscovery, updateDiscoveryCall} from "../discoveryCallSlice";
import {useDispatch, useSelector} from "react-redux";
import TextArea from "../Form/TextArea";
import {getTopicNotes} from "../../../utilities/utilities";
import {handleUpdateTopicNotes} from "../../../api/discovery-call";

const Budget = () => {
    const currentDisco = useSelector(selectCurrentDiscovery);
    const dispatch = useDispatch();
    let [values, setValues] = useState({});
    let [showBudgetCreator, setShowBudgetCreator] = useState(false);

    const handleFieldChange = (event) => {
        const {name, value} = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleUpdateBudget = (event) => {
        const {name, value} = event.target;

        const formattedVal = name === 'budget' || name === 'ad_spend' ? parseFloat(value) : value;

        dispatch(updateDiscoveryCall(
            'discovery_budgets',
            'updateDiscoveryBudget',
            {id: parseInt(event.target.dataset.id), [name]: formattedVal},
            currentDisco.id
        ));
    };

    const saveNewBudget = async () => {
        const newBudget = document.getElementById('new-budget');
        const newAdSpend = document.getElementById('new-ad-spend');
        const newNotes = document.getElementById('new-notes');

        await dispatch(updateDiscoveryCall(
            'discovery_budgets',
            'createDiscoveryBudget',
            {
                discovery_id: currentDisco.id,
                budget: parseFloat(newBudget.value),
                ad_spend: parseFloat(newAdSpend.value),
                notes: newNotes.value
            },
            currentDisco.id
        ));

        newBudget.value = '';
        newAdSpend.value = '';
        newNotes.value = '';
        setShowBudgetCreator(false);
    };

    return (
        <div className="box" style={{padding: '2rem'}}>
            <div className="discovery-form budget columns is-centered is-vcentered is-mobile is-multiline">

                <div className="column is full">

                    <label className="label">
                        What is your monthly marketing budget?
                    </label>

                    {currentDisco.budgets.length > 0 && currentDisco.budgets.map((budget, i) => {
                     return (
                         <div className="columns is-centered is-vcentered is-multiline fieldset-row" key={`budget-${i}`}>
                             <div className="disco-form-field column is-two-fifths">
                                 <SecondaryLabel text="Total Management Budget" />
                                 <Text
                                     name={'budget'}
                                     defaultValue={budget.budget}
                                     onChange={handleFieldChange}
                                     onBlur={handleUpdateBudget}
                                     dataId={budget.id}
                                 />
                             </div>
                             <div className="disco-form-field column">
                                 <SecondaryLabel text="Ad Spend" />
                                 <Text
                                     name={'ad_spend'}
                                     defaultValue={budget.ad_spend}
                                     onChange={handleFieldChange}
                                     onBlur={handleUpdateBudget}
                                     dataId={budget.id}
                                 />
                             </div>
                             <div className="disco-form-field column is-two-fifths">
                                 <SecondaryLabel text="Notes" />
                                 <Text
                                     name={'notes'}
                                     defaultValue={budget.notes}
                                     onChange={handleFieldChange}
                                     onBlur={handleUpdateBudget}
                                     dataId={budget.id}
                                 />
                             </div>
                         </div>
                     )
                    })}

                    {showBudgetCreator &&
                    <div className="columns is-centered is-vcentered is-multiline fieldset-row">
                        <div className="disco-form-field column is-two-fifths">
                            <SecondaryLabel text="Total Management Budget" />
                            <Text
                                name={'budget'}
                                id={'new-budget'}
                                defaultValue={'0'}
                            />
                        </div>
                        <div className="disco-form-field column">
                            <SecondaryLabel text="Ad Spend" />
                            <Text
                                name={'ad_spend'}
                                id={'new-ad-spend'}
                                defaultValue={'0'}
                            />
                        </div>
                        <div className="disco-form-field column is-two-fifths">
                            <SecondaryLabel text="Notes" />
                            <Text
                                name={'notes'}
                                id={'new-notes'}
                                defaultValue={''}
                            />
                        </div>
                        <div className="disco-form-field column is-full add-fields-column">
                            <button className="add-fields-button" onClick={saveNewBudget}>Save</button>
                        </div>
                    </div>
                    }

                    {currentDisco.budgets.length === 0 && showBudgetCreator === false &&
                        <div className="column add-row" onClick={() => setShowBudgetCreator(true)} style={{marginTop: 20}}>
                            <span className="plus-btn"></span><span>Add a new budget</span>
                        </div>
                    }

                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">Other notes</label>
                    <TextArea
                        name={'budget-topic-notes'}
                        defaultValue={getTopicNotes(currentDisco.topics,1006)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateTopicNotes(currentDisco.id, 1006, dispatch)}
                    />
                </div>

            </div>
        </div>
    )

};

export default Budget;