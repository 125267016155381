import React from 'react';

const Text = (props) => {

    let classes = ['input'];
    if (props.addlClasses) {
        classes = [...classes, ...props.addlClasses]
    }

    return (
        <div className="field" style={(props.field || {}).wrapperStyle || props.wrapperStyle || {}}>
            <div className="control">
                <input
                    name={(props.field || {}).name || props.name || ''}
                    id={props.id}
                    defaultValue={props.defaultValue}
                    value={props.value}
                    className={classes.join(' ')}
                    type="text"
                    placeholder={(props.field || {}).placeholder || props.placeholder || ''}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    data-id={props.dataId || null}
                    readOnly={props.readOnly}
                    style={props.inputStyle || {}}
                />
            </div>
        </div>
    )
};

export default Text;