import React from 'react';
import {getNovaUrlBase} from "../../utilities/utilities";

const DiscoveryHeader = (props) => {
    return (
        <div className="breadcrumbs">
            <a className="home-icon" href={`${getNovaUrlBase()}/`}>
                <img src="../../img/nova-home_ICON.svg" alt="Nova" />
            </a>
            <span className="breadcrumb-divider"></span>
            <a href={`${getNovaUrlBase()}/audits`} style={{color: '#85a9ce', fontWeight: 600}}>All Audits</a>
            <span className="breadcrumb-divider"></span>
            <span>{props.clientName} Discovery</span>
            <h3 className="page-heading">{props.clientName} Discovery</h3>
        </div>
    )
}

export default DiscoveryHeader