import React, {useState} from 'react';
import {getAnswer, getTopicNotes} from "../../../utilities/utilities";
import {handleUpdateDate, handleUpdateQuestion, handleUpdateTopicNotes} from "../../../api/discovery-call";
import TextArea from "../Form/TextArea";
import SecondaryLabel from "../Form/Pieces/SecondaryLabel";
import Text from "../Form/Text";
import moment from "moment";
import DatePicker from "../Form/DatePicker";
import {selectCurrentDiscovery, updateDiscoveryCall} from "../discoveryCallSlice";
import {useDispatch, useSelector} from "react-redux";

const BuyingCriteria = () => {
    const currentDisco = useSelector(selectCurrentDiscovery);
    const dispatch = useDispatch();
    let [values, setValues] = useState({});
    let [shouldShowCreator, setShouldShowCreator] = useState(false);

    const handleFieldChange = (event) => {
        const {name, value} = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
        const name = dayPickerInput.props.name;

        const formatted = moment(selectedDay).format("MM/DD/YYYY");

        if (name === "question-new-partner-timeline") {
            handleUpdateDate(1016, currentDisco.id, formatted, dispatch);
        }

        if (name === "question-strategy-launch-date") {
            handleUpdateDate(1017, currentDisco.id, formatted, dispatch);
        }
    };

    const getDateValue = (questionId) => {
        const existingAns = getAnswer(currentDisco.questions, questionId);
        if (existingAns) {
            return moment(existingAns).format("MM/DD/YYYY");
        } else {
            return undefined;
        }
    };

    const setDecisionMaker = async (e) => {
        const {id, checked} = e.target;
        let value = "no";

        if (checked) {
            value = "yes";
        }

        console.log('setDecisionMaker - id', id);
        console.log('setDecisionMaker - checked', checked);
        console.log('setDecisionMaker - value', value);

        await dispatch(updateDiscoveryCall(
            'contacts',
            'updateContact',
            {id: parseInt(id), 'is_decision_maker': value},
            currentDisco.id
        ));
    };

    const checkIfDecisionMaker = (id) => {
        const _contacts = currentDisco.client.contacts.filter(contact => {
            return contact.id === id;
        });

        if (_contacts.length) {
            const value = (_contacts[0].is_decision_maker);
            return value === "yes";
        }
    };

    const saveNewContact = async () => {
        const newName = document.getElementById('new-name');
        const newEmail = document.getElementById('new-email');
        const newTitle = document.getElementById('new-title');

        await dispatch(updateDiscoveryCall(
            'contacts',
            'createContact',
            {client_id: currentDisco.client.id, name: newName.value, email: newEmail.value, title: newTitle.value},
            currentDisco.id
        ));

        newName.value = '';
        newEmail.value = '';
        newTitle.value = '';
        setShouldShowCreator(false);
    };

    return (
        <div className="box" style={{padding: '2rem'}}>
            <div className="discovery-form buying-criteria columns is-centered is-vcentered is-mobile is-multiline">
                <div className="disco-form-field column is-full">
                    <label className="label">What is your timeline for bringing on a new partner?</label>
                    <DatePicker
                        name="question-new-partner-timeline"
                        value={getDateValue(1016)}
                        onChange={handleDayChange}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">What is the date to launch the campaign strategy?</label>
                    <DatePicker
                        name="question-strategy-launch-date"
                        value={getDateValue(1017)}
                        onChange={handleDayChange}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">What are you basing your decision on?</label>
                    <TextArea
                        name="question-decision-criteria"
                        defaultValue={getAnswer(currentDisco.questions,1018)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1018, currentDisco.id, dispatch,(val) => {return `""${val}""`;})}
                        rows={"4"}
                    />
                </div>

                <div className="column is full">

                    <label className="label">
                        Who is involved in the decision making process?
                    </label>

                    <div className="columns is-centered is-vcentered is-multiline fieldset-row">
                        <div className="disco-form-field column is-full">
                            <div className="field">
                                <div className="control">
                                    {currentDisco.client.contacts
                                    && currentDisco.client.contacts.length > 0
                                    && currentDisco.client.contacts.map((contact, index) => {
                                    let name = contact.name.replace(/\W+/g, '-').toLowerCase();
                                        return (
                                            <label className="checkbox" key={`${name}-${index}`} style={{marginRight: 25}}>
                                                <input
                                                    type="checkbox"
                                                    name={name}
                                                    id={contact.id}
                                                    checked={checkIfDecisionMaker(contact.id)}
                                                    style={{marginRight: 5}}
                                                    onChange={setDecisionMaker}
                                                />
                                                <span style={{fontSize: '0.85rem'}}>{contact.name}</span>
                                            </label>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    {shouldShowCreator &&
                    <div className="columns is-centered is-vcentered is-multiline fieldset-row" >
                        <div className="disco-form-field column">
                            <SecondaryLabel text="Name" />
                            <Text
                                name={'name'}
                                id={'new-name'}
                                defaultValue={''}
                            />
                        </div>
                        <div className="disco-form-field column">
                            <SecondaryLabel text="Email" />
                            <Text
                                name={'email'}
                                id={'new-email'}
                                defaultValue={''}
                            />
                        </div>
                        <div className="disco-form-field column">
                            <SecondaryLabel text="Title" />
                            <Text
                                name={'title'}
                                id={'new-title'}
                                defaultValue={''}
                            />
                        </div>
                        <div className="disco-form-field column is-full add-fields-column">
                            <button className="add-fields-button" onClick={saveNewContact}>Save</button>
                        </div>
                    </div>
                    }

                    {!shouldShowCreator &&
                    <div className="column add-row" onClick={() => setShouldShowCreator(true)}>
                        <span className="plus-btn"></span><span>Add a new contact</span>
                    </div>
                    }

                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">Other notes</label>
                    <TextArea
                        name={'buying-criteria-topic-notes'}
                        defaultValue={getTopicNotes(currentDisco.topics,1007)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateTopicNotes(currentDisco.id, 1007, dispatch)}
                    />
                </div>

            </div>
        </div>
    )

};

export default BuyingCriteria;