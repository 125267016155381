import React, {useState} from 'react';
import {getAnswer, getTopicNotes} from "../../../utilities/utilities";
import {handleUpdateDate, handleUpdateQuestion, handleUpdateTopicNotes} from "../../../api/discovery-call";
import TextArea from "../Form/TextArea";
import DatePicker from "../Form/DatePicker";
import SecondaryLabel from "../Form/Pieces/SecondaryLabel";
import moment from "moment";
import {selectCurrentDiscovery, updateDiscoveryCall} from "../discoveryCallSlice";
import {useDispatch, useSelector} from "react-redux";

const AuditFraming = () => {
    const currentDisco = useSelector(selectCurrentDiscovery);
    const dispatch = useDispatch();
    let [values, setValues] = useState({});

    const handleFieldChange = (event) => {
        const {name, value} = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const checkIfPriority = (id) => {
        const filtered = currentDisco.services.filter(svc => {
            return svc.id === id;
        });

        if (filtered.length) {
            const value = (filtered[0].is_priority);
            return value === "yes";
        }
    };

    const setServicePriority = async (e) => {
        const {id, checked} = e.target;
        let value = "no";

        if (checked) {
            value = "yes";
        }

        await dispatch(updateDiscoveryCall(
            'discovery_services',
            'updateDiscoveryService',
            {
                discovery_id: currentDisco.id,
                service_id: parseInt(id),
                is_priority: value
            },
            currentDisco.id
        ));
    };

    const handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
        const name = dayPickerInput.props.name;

        const formatted = moment(selectedDay).format("MM/DD/YYYY");

        if (name === "question-audit-presentation") {
            handleUpdateDate(1022, currentDisco.id, formatted, dispatch);
        }
    };

    const getDateValue = (questionId) => {
        const existingAns = getAnswer(currentDisco.questions, questionId);
        if (existingAns) {
            return moment(existingAns).format("MM/DD/YYYY");
        } else {
            return undefined;
        }
    };

    return (
        <div className="box" style={{padding: '2rem'}}>
            <div className="discovery-form audit-framing columns is-centered is-vcentered is-mobile is-multiline">

                <div className="disco-form-field column is-full">
                    <label className="label">Which channels/services are we auditing?</label>
                    <div className="field">
                        <div className="control">
                            {currentDisco.services.length === 0 &&
                                <SecondaryLabel text="No services have been added yet" />
                            }
                            {currentDisco.services.length > 0 && currentDisco.services.map((svc, index) => {
                                let name = svc.name.replace(/\W+/g, '-').toLowerCase();
                                return (
                                    <label className="checkbox" key={`${name}-${index}`} style={{marginRight: 25}}>
                                        <input
                                            type="checkbox"
                                            name={name}
                                            id={svc.id}
                                            checked={checkIfPriority(svc.id)}
                                            style={{marginRight: 5}}
                                            onChange={setServicePriority}
                                        />
                                        <span style={{fontSize: '0.85rem'}}>{svc.name}</span>
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">What accesses are we requesting?</label>
                    <TextArea
                        name="question-access-request"
                        defaultValue={getAnswer(currentDisco.questions,1021)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1021, currentDisco.id, dispatch,(val) => {return `""${val}""`;})}
                        rows={4}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">When is the audit presentation?</label>
                    <DatePicker
                        name="question-audit-presentation"
                        value={getDateValue(1022)}
                        onChange={handleDayChange}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">Other notes</label>
                    <TextArea
                        name={'audit-framing-topic-notes'}
                        defaultValue={getTopicNotes(currentDisco.topics,1008)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateTopicNotes(currentDisco.id, 1008, dispatch)}
                    />
                </div>

            </div>
        </div>
    )

};

export default AuditFraming;