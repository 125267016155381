import React from 'react';
import PropTypes from 'prop-types';
import TilesSubMenu from "./TilesSubMenu";

const Tiles = (props) => {

    let columnClasses = [];
    if (props.columnClasses) {
        columnClasses = [...columnClasses, props.columnClasses]
    }

    return (

        <div className={`columns is-multiline tiles-wrapper ${props.wrapperClass || ''}`} style={props.wrapperStyle}>

            {props.heading &&
                <p className="column is-full tiles-heading">{props.heading}</p>
            }

            {props.items.map((item, index) => {
                const handle = item.handle ? item.handle : item.name.replace(/\W+/g, '-').toLowerCase();

                let tileClasses = ['box', 'has-text-centered'];
                let activeIcon = false;

                if (props.activeItem === handle) {
                    tileClasses.push('active');
                    activeIcon = true;
                }

                if (props.addlTileClasses) {
                    tileClasses = [...tileClasses, ...props.addlTileClasses];
                }

                return (
                    <div className={`column tile-item ${columnClasses.join(' ')}`} key={handle}>
                        <div
                            className={tileClasses.join(' ')}
                            onClick={() => props.onViewChange(handle)}
                            style={props.itemStyle}
                        >
                            <div className="text-container">
                                {props.showType &&
                                <span className="tile-type is-unselectable">{item.type}</span>
                                }
                                <span className="tile-title is-unselectable">{item.name}</span>
                            </div>

                            {typeof props.getIcon !== 'undefined' && props.getIcon(handle, activeIcon)}

                            {props.enableSubMenu &&
                            props.activeItem === handle &&
                                <TilesSubMenu
                                    item={item}
                                    subMenuClasses={props.subMenuClasses}
                                    activeSubMenuItems={props.activeSubMenuItems}
                                    onSubMenuSelect={props.onSubMenuSelect}
                                />
                            }

                        </div>
                    </div>
                )
            })}
            </div>

    )

};

export default Tiles;

Tiles.defaultProps = {
    showType: false,
};

Tiles.propTypes = {
    items: PropTypes.array,
    activeItem: PropTypes.string,
    onViewChange: PropTypes.func,
    wrapperClass: PropTypes.string,
    columnClasses: PropTypes.array,
    addlTileClasses: PropTypes.array,
    wrapperStyle: PropTypes.object,
    itemStyle: PropTypes.object,
    showType: PropTypes.bool,
    enableSubMenu: PropTypes.bool,
    subMenuClasses: PropTypes.array,
    onSubMenuSelect: PropTypes.func,
    activeSubMenuItems: PropTypes.array
};