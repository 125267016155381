import React, {useState} from 'react';
import SecondaryLabel from "../Form/Pieces/SecondaryLabel";
import Text from "../Form/Text";
import {getAnswer, getTopicNotes} from "../../../utilities/utilities";
import {handleUpdateQuestion, handleUpdateTopicNotes} from "../../../api/discovery-call";
import TextArea from "../Form/TextArea";
import {selectCurrentDiscovery, updateDiscoveryCall} from "../discoveryCallSlice";
import {useDispatch, useSelector} from "react-redux";

const ProductsRevenue = () => {
    const currentDisco = useSelector(selectCurrentDiscovery);
    const dispatch = useDispatch();

    const businessType = currentDisco.business_type.id;

    let [values, setValues] = useState({});
    let [showProductCreator, setShowProductCreator] = useState(false);

    const handleFieldChange = (event) => {
        const {name, value} = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleUpdateProduct = (event) => {
        const {name, value} = event.target;

        const formattedVal = name === 'cost' || name === 'margin' ? parseFloat(value) : value;

        dispatch(updateDiscoveryCall(
            'discovery_products',
            'updateDiscoveryProduct',
            {id: parseInt(event.target.dataset.id), [name]: formattedVal},
            currentDisco.id
        ));
    };

    const saveNewProduct = async () => {
        const newName = document.getElementById('new-name');
        const newCost = document.getElementById('new-cost');
        const newMargin = document.getElementById('new-margin');

        await dispatch(updateDiscoveryCall(
            'discovery_products',
            'createDiscoveryProduct',
            {
                discovery_id: currentDisco.id,
                name: newName.value,
                cost: parseFloat(newCost.value),
                margin: parseFloat(newMargin.value)
            },
            currentDisco.id
        ));

        newName.value = '';
        newCost.value = '';
        newMargin.value = '';
        setShowProductCreator(false);

    };

    return (
        <div className="box" style={{padding: '2rem'}}>
            <div className="discovery-form products-revenue columns is-centered is-vcentered is-mobile is-multiline">

                <div className="column is-full">

                    <label className="label">
                        {businessType === 1002 &&
                            <span>What is the most popular product(s)? What is the current cost & margin on your products?</span>
                        }
                        {(businessType === 1001 || businessType === 1003) &&
                            <span>What is the most popular product(s) or service(s)? What is the current cost & margin on your product(s) or service(s)?</span>
                        }
                    </label>

                    {currentDisco.products.length > 0 && currentDisco.products.map((product, i) => {
                        return (
                            <div className="columns is-vcentered is-multiline fieldset-row" key={`product-${i}`}>
                                <div className="disco-form-field column is-8">
                                    <SecondaryLabel text="Product Name" />
                                    <Text
                                        name={'name'}
                                        defaultValue={product.name}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateProduct}
                                        dataId={product.id}
                                    />
                                </div>
                                <div className="disco-form-field column is-2">
                                    <SecondaryLabel text="Product Cost" />
                                    <Text
                                        name={'cost'}
                                        defaultValue={product.cost}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateProduct}
                                        dataId={product.id}
                                    />
                                </div>
                                <div className="disco-form-field column is-2">
                                    <SecondaryLabel text="Product Margin" />
                                    <Text
                                        name={'margin'}
                                        defaultValue={product.margin}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateProduct}
                                        dataId={product.id}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    {showProductCreator &&
                        <div className="columns is-vcentered is-multiline fieldset-row">
                            <div className="disco-form-field column is-8">
                                <SecondaryLabel text="Product Name" />
                                <Text
                                    name={'name'}
                                    id={'new-name'}
                                    defaultValue={''}
                                />
                            </div>
                            <div className="disco-form-field column is-2">
                                <SecondaryLabel text="Product Cost" />
                                <Text
                                    name={'cost'}
                                    id={'new-cost'}
                                    defaultValue={'0.00'}
                                />
                            </div>
                            <div className="disco-form-field column is-2">
                                <SecondaryLabel text="Product Margin" />
                                <Text
                                    name={'margin'}
                                    id={'new-margin'}
                                    defaultValue={'0.00'}
                                />
                            </div>
                            <div className="disco-form-field column is-full add-fields-column">
                                <button className="add-fields-button" onClick={saveNewProduct}>Save</button>
                            </div>
                        </div>
                    }

                    {!showProductCreator &&
                    <div className="column add-row" onClick={() => setShowProductCreator(true)}>
                        <span className="plus-btn"></span><span>Add a new product</span>
                    </div>
                    }

                </div>

                <div className="disco-form-field column is-half">
                    <label className="label">What is the AOV of a customer's first order?</label>
                    <TextArea
                        name="question-aov"
                        defaultValue={getAnswer(currentDisco.questions, 1010)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1010, currentDisco.id, dispatch)}
                        rows={1}
                        style={{resize: 'none'}}
                        isCurrency={true}
                    />
                </div>

                <div className="disco-form-field column is-half">
                    <label className="label">What is the average LTV of a customer?</label>
                    <TextArea
                        name="question-avg-ltv"
                        defaultValue={getAnswer(currentDisco.questions, 1011)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1011, currentDisco.id, dispatch)}
                        rows={1}
                        style={{resize: 'none'}}
                        isCurrency={true}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    {businessType === 1002 &&
                        <label className="label">How do you acquire customers?</label>
                    }
                    {(businessType === 1001 || businessType === 1003) &&
                        <label className="label">Are you doing any cross-selling to existing customers to increase LTV?  What's the next complementary product/service they will buy?</label>
                    }
                    <TextArea
                        name="question-increase-ltv"
                        defaultValue={getAnswer(currentDisco.questions, 1012)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1012, currentDisco.id, dispatch,(val) => {return `""${val}""`;})}
                        rows={4}
                        style={{resize: 'none'}}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">What’s your unique selling proposition?</label>
                    <TextArea
                        name="question-selling-prop"
                        defaultValue={getAnswer(currentDisco.questions, 1048)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1048, currentDisco.id, dispatch, (val) => {return `""${val}""`;})}
                        rows={4}
                        style={{resize: 'none'}}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">Other notes</label>
                    <TextArea
                        name={'products-topic-notes'}
                        defaultValue={getTopicNotes(currentDisco.topics,1004)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateTopicNotes(currentDisco.id, 1004, dispatch)}
                    />
                </div>

            </div>
        </div>
    )

};

export default ProductsRevenue;