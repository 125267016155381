import React, {useEffect, useState} from 'react';
import SecondaryLabel from "../Form/Pieces/SecondaryLabel";
import Text from "../Form/Text";
import Select from "../Form/Select";
import TextArea from "../Form/TextArea";
import {getAnswer, getQuestionText, getTopicNotes} from "../../../utilities/utilities";
import {selectCurrentDiscovery, updateDiscoveryCall} from "../discoveryCallSlice";
import {useDispatch, useSelector} from "react-redux";
import {handleUpdateQuestion, handleUpdateTopicNotes} from "../../../api/discovery-call";

const PreCall = (props) => {
    let [currentClient, setCurrentClient] = useState({});
    let [shouldShowCreator, setShouldShowCreator] = useState(false);
    let [businessType, setBusinessType] = useState(null);
    let [urlReadOnly, setUrlReadOnly] = useState(true);

    const currentDisco = useSelector(selectCurrentDiscovery);
    const dispatch = useDispatch();

    useEffect(() => {
        setBusinessType(currentDisco.business_type.id);
    }, []);

    useEffect(() => {
        setCurrentClient(currentDisco.client);
    }, [currentDisco])

    const getFieldValue = (name) => {
        if (Object.keys(currentClient).length > 0) {
            if (name === "client_name") {
                return currentClient.name;
            }
            if (name === "client_website") {
                return currentClient.website;
            }
            if (name === "industry") {
                return currentClient.industry.id;
            }
            if (name === "lead_source") {
                return currentClient.lead_source.id;
            }
        }

    };

    const handleFieldChange = (event) => {
        const {name, value} = event.target;

        if (name === "client_name") {
            setCurrentClient({
                ...currentClient,
                name: value
            })
        }

        if (name === "client_website") {
            setCurrentClient({
                ...currentClient,
                website: value
            })
        }

        if (name === "business_type_id") {
            setBusinessType(parseInt(value));
        }

        if (name === "industry") {
            setCurrentClient({
                ...currentClient,
                industry: {
                    id: parseInt(value)
                }
            })
        }

        if (name === "lead_source") {
            setCurrentClient({
                ...currentClient,
                lead_source: {
                    id: parseInt(value)
                }
            })
        }
    };

    /**
     * @param {string} fieldName
     *  name of the field being updated
     * @param {string} collection
     *  collection or db table where the fieldName lives
     * @param {string} mutation
     *  type of mutation to call
     * @param {Function} formatter
     * @returns {function(...[*]=)}
     */
    const handleUpdateField = (fieldName, collection = 'clients', mutation = 'updateClient', formatter = (val) => {return val;}) => (event) => {
        const {name, value} = event.target;

        if (name === "business_type_id") {
            // need to track business type in local state
            setBusinessType(parseInt(value));

            dispatch(updateDiscoveryCall(
                collection,
                mutation,
                {id: currentDisco.id, [fieldName || name]: formatter(value)},
                currentDisco.id
                ),
            );
        } else {
            dispatch(updateDiscoveryCall(
                collection,
                mutation,
                {id: currentDisco.client.id, [fieldName || name]: formatter(value)},
                currentDisco.id
                ),
            );
        }

    };

    const handleUpdateContact = (event) => {
        const {name, value} = event.target;

        let val = value;

        if (name === "notes") {
            val = `""${value}""`
        }

        dispatch(updateDiscoveryCall(
            'contacts',
            'updateContact',
            {id: parseInt(event.target.dataset.id), [name]: val},
            currentDisco.id
        ));

    };

    const saveNewContact = async () => {
        const newName = document.getElementById('new-name');
        const newEmail = document.getElementById('new-email');
        const newTitle = document.getElementById('new-title');
        const newNotes = document.getElementById('new-notes');

        await dispatch(updateDiscoveryCall(
            'contacts',
            'createContact',
            {
                client_id: currentDisco.client.id,
                name: newName.value,
                email: newEmail.value,
                title: newTitle.value,
                notes: `""${newNotes.value}""`
            },
            currentDisco.id
        ));

        newName.value = '';
        newEmail.value = '';
        newTitle.value = '';
        newNotes.value = '';
        setShouldShowCreator(false);
    };

    const renderEditButton = () => {
        let value;
        if (urlReadOnly === true) {
            value = <><img src="../../../img/pencil.svg" /><span>Edit</span></>;
        } else {
            value = <><img src="../../../img/save.svg" /><span>Save</span></>;
        }
        return <div className="edit-field-button" onClick={() => setUrlReadOnly(!urlReadOnly)}>{value}</div>
    };

    const clientSiteLink = getFieldValue('client_website');

    return (
        <div className="box" style={{padding: '2rem'}}>
            <div className="discovery-form pre-call columns is-centered is-vcentered is-mobile is-multiline">

                <div className="disco-form-field column is-full">
                    <label className="label">Company Name</label>
                    <Text
                        name="client_name"
                        value={getFieldValue("client_name")}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateField('name')}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <div style={{display: 'block'}}>
                        <label className="label" style={{display: 'inline-block', marginRight: 15}}>Website URL</label>
                        {renderEditButton()}
                    </div>
                    {urlReadOnly &&
                        <a href={clientSiteLink} target="_blank" className="url-read-only">{clientSiteLink}</a>
                    }
                    {!urlReadOnly &&
                        <Text
                            name="client_website"
                            value={getFieldValue("client_website")}
                            onChange={handleFieldChange}
                            onBlur={handleUpdateField('website')}
                        />
                    }
                </div>

                <div className="disco-form-field column is-one-third">
                    <label className="label">Business Type</label>
                    <Select
                        name="business_type_id"
                        value={businessType || currentDisco.business_type.id}
                        onChange={e => {
                            handleFieldChange(e);
                            handleUpdateField(
                                'business_type_id',
                                'discoveries',
                                'updateDiscovery',
                                parseInt
                            )(e);
                        }}
                        options="business_types"
                        fullWidth={true}
                    />
                </div>

                <div className="disco-form-field column is-one-third">
                    <label className="label">Industry</label>
                    <Select
                        name="industry"
                        value={getFieldValue('industry')}
                        onChange={e => {
                            handleFieldChange(e);
                            handleUpdateField(
                                'industry_id',
                                undefined,
                                undefined,
                                parseInt
                            )(e);
                        }}
                        options="industries"
                        fullWidth={true}
                    />
                </div>

                <div className="disco-form-field column is-one-third">
                    <label className="label">Lead Source</label>
                    <Select
                        name="lead_source"
                        value={getFieldValue('lead_source')}
                        onChange={e => {
                            handleFieldChange(e);
                            handleUpdateField(
                                'lead_source_id',
                                undefined,
                                undefined,
                                parseInt
                            )(e);
                        }}
                        options="lead_sources"
                        fullWidth={true}
                    />
                </div>

                <div className="column is full">

                    <label className="label">Client Contact Information</label>

                    {currentDisco.client.contacts
                    && currentDisco.client.contacts.length > 0
                    && currentDisco.client.contacts.map((contact, i) => {
                        return (
                            <div className="columns is-centered is-vcentered is-multiline fieldset-row" key={`contact-${i}`}>
                                <div className="disco-form-field column is-one-third">
                                    <SecondaryLabel text="Name" />
                                    <Text
                                        name={'name'}
                                        defaultValue={contact.name}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateContact}
                                        dataId={contact.id}
                                    />
                                </div>
                                <div className="disco-form-field column is-one-third">
                                    <SecondaryLabel text="Email" />
                                    <Text
                                        name={'email'}
                                        defaultValue={contact.email}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateContact}
                                        dataId={contact.id}
                                    />
                                </div>
                                <div className="disco-form-field column is-one-third">
                                    <SecondaryLabel text="Title" />
                                    <Text
                                        name={'title'}
                                        defaultValue={contact.title}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateContact}
                                        dataId={contact.id}
                                    />
                                </div>
                                <div className="disco-form-field column is-full" style={{paddingTop: 0, marginTop: '-5px'}}>
                                    <TextArea
                                        name={'notes'}
                                        defaultValue={contact.notes}
                                        onChange={handleFieldChange}
                                        onBlur={handleUpdateContact}
                                        dataId={contact.id}
                                        collapsible={true}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    {shouldShowCreator &&
                    <div className="columns is-centered is-vcentered is-multiline fieldset-row" >
                        <div className="disco-form-field column">
                            <SecondaryLabel text="Name" />
                            <Text
                                name={'name'}
                                id={'new-name'}
                                defaultValue={''}
                            />
                        </div>
                        <div className="disco-form-field column">
                            <SecondaryLabel text="Email" />
                            <Text
                                name={'email'}
                                id={'new-email'}
                                defaultValue={''}
                            />
                        </div>
                        <div className="disco-form-field column">
                            <SecondaryLabel text="Title" />
                            <Text
                                name={'title'}
                                id={'new-title'}
                                defaultValue={''}
                            />
                        </div>
                        <div className="disco-form-field column is-full">
                            <SecondaryLabel text="Contact Details" />
                            <TextArea
                                name={'notes'}
                                id={'new-notes'}
                                defaultValue={''}
                            />
                        </div>
                        <div className="disco-form-field column is-full add-fields-column">
                            <button className="add-fields-button" onClick={saveNewContact}>Save</button>
                        </div>
                    </div>
                    }

                    {!shouldShowCreator &&
                    <div className="column add-row" onClick={() => setShouldShowCreator(true)}>
                        <span className="plus-btn"></span><span>Add a new contact</span>
                    </div>
                    }

                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">{getQuestionText(props.questions, 1045)}</label>
                    <TextArea
                        name={'opening-draft'}
                        defaultValue={getAnswer(currentDisco.questions, 1045)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1045, currentDisco.id, dispatch,(val) => {return `""${val}""`;})}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">{getQuestionText(props.questions, 1046)}</label>
                    <TextArea
                        name={'company-business-model'}
                        defaultValue={getAnswer(currentDisco.questions, 1046)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1046, currentDisco.id, dispatch,(val) => {return `""${val}""`;})}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">{getQuestionText(props.questions, 1047)}</label>
                    <TextArea
                        name={'similar-clients'}
                        defaultValue={getAnswer(currentDisco.questions, 1047)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1047, currentDisco.id, dispatch,(val) => {return `""${val}""`;})}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">{getQuestionText(props.questions, 1003)}</label>
                    <TextArea
                        name={'precall-opportunities'}
                        defaultValue={getAnswer(currentDisco.questions, 1003)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateQuestion(1003, currentDisco.id, dispatch,(val) => {return `""${val}""`;})}
                    />
                </div>

                <div className="disco-form-field column is-full">
                    <label className="label">Other notes</label>
                    <TextArea
                        name={'precall-topic-notes'}
                        defaultValue={getTopicNotes(currentDisco.topics,1001)}
                        onChange={handleFieldChange}
                        onBlur={handleUpdateTopicNotes(currentDisco.id, 1001, dispatch)}
                    />
                </div>

            </div>
        </div>
    )

};

export default PreCall;